import { UserCircleIcon, ArrowPathRoundedSquareIcon } from "@heroicons/react/24/solid";
import { Link } from 'react-router-dom';
import { Typography } from "@material-tailwind/react";

import { useAuth0 } from "@auth0/auth0-react";

export const LoginButton = () => {
    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
    
    const login = async () => {
        sessionStorage.setItem("redirectPathAfterAuth", window.location.pathname ?? '/');
        loginWithRedirect();
    };

    return (
        !isAuthenticated && (
            <div className="px-6" onClick={() => login()}>
                {isLoading && <ArrowPathRoundedSquareIcon className="h-5 w-5 mt-0.5 absolute flex" />}
                {!isLoading && <UserCircleIcon className="h-5 w-5 mt-0.5 absolute flex" />}
                <Typography
                    as="li"
                    className="pl-6 font-normal"
                >
                    <Link className="flex items-center hover:font-bold">Log In</Link>
                </Typography>
            </div>
        )
    );
}
