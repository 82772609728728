import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const UserService = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const authoriseUser = async () => {
    const baseUrl = getApiBaseUrl();
    const token = await getAccessTokenSilently({
        authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          }
    });
    
    await fetch(`${baseUrl}/Users`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    })
    .catch(error => console.error(error));

    navigate(sessionStorage.getItem("redirectPathAfterAuth"));
  };
    
  const getApiBaseUrl = () => {
      const uiUrl = window.location.origin;
      switch(uiUrl) {
          case 'http://localhost:3000':
              return 'https://localhost:7214';
          case 'https://salmon-field-0d9fc360f.2.azurestaticapps.net':
              return 'https://dictionary-api.azurewebsites.net';
          default:
              return 'https://dictionary-api.azurewebsites.net';
      }
  };

  React.useEffect(() => {
    authoriseUser();
  });

  return (
    <>Loading...</>
  );
};

export default UserService;