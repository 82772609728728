import React, { useState } from "react";
import { Link } from 'react-router-dom';
import {
  Navbar,
  Collapse,
  Typography,
  IconButton
} from "@material-tailwind/react";
import {
  HomeIcon,
  CircleStackIcon,
  // SpeakerWaveIcon,
  // ChatBubbleLeftIcon,
  // AdjustmentsHorizontalIcon,
  // GlobeAmericasIcon,
  PencilSquareIcon
} from "@heroicons/react/24/solid";
import { FeedbackDialog } from '../feedback/feedback-dialog';

import { LoginButton } from '../auth/LoginButton';
import { LogoutButton } from '../auth/LogoutButton';
import { authoriseFeature } from "../../feature-flags";

export default function MainNavigation() {
  const [openNav, setOpenNav] = useState(false);
  const [currentPage, setCurrentPage] = useState('home');
  const [openFeedback, setOpenFeedback] = useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );

    const path = window.location.pathname.replace('/', '');
    setCurrentPage(!!path ? path : 'home');
  }, []);

  const getLinkClass = (link) => {
    return `${currentPage===link ? 'font-bold ': ''}flex items-center hover:font-bold`
  }

  const handleFeedback = () => {
    setOpenFeedback(!openFeedback);
  }

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-12">
      <div onClick={() => setCurrentPage('home')}>
        <HomeIcon className="h-4 w-5 mt-0.5 absolute flex" />
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="pl-6 font-normal"
        >
          <Link to='/' className={getLinkClass('home')}>Home</Link>
        </Typography>
      </div>
      <div onClick={() => setCurrentPage('dictionary')}>
        <CircleStackIcon className="h-4 w-5 mt-0.5 absolute flex" />
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="pl-6 font-normal"
        >
          <Link to='/dictionary' className={getLinkClass('dictionary')}>Dictionary</Link>
        </Typography>
      </div>
      {/* <div>
        <SpeakerWaveIcon className="h-4 w-5 mt-0.5 absolute flex" />
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="pl-6 font-normal"
        >
          <Link to='/word-of-the-day' className="flex items-center">Word of the day</Link>
        </Typography>
      </div> */}
      {/* <div>
        <ChatBubbleLeftIcon className="h-4 w-5 mt-0.5 absolute flex" />
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="pl-6 font-normal"
        >
          <Link to='/about-sesotho' className="flex items-center">About Sesotho</Link>
        </Typography>
      </div> */}
      {/* <div>
        <AdjustmentsHorizontalIcon className="h-4 w-5 mt-0.5 absolute flex" />
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="pl-6 font-normal"
        >
          <Link to='/about-futurelex' className="flex items-center">About Futurelex</Link>
        </Typography>
      </div> */}
      {/* <div>
        <GlobeAmericasIcon className="h-4 w-5 mt-0.5 absolute flex" />
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="pl-6 font-normal"
        >
          <Link to='/sesetho-platform' className="flex items-center">Sesotho Platform</Link>
        </Typography>
      </div> */}
    </ul>
  );

  return (
    <>
      <Navbar className="sticky inset-0 z-10 h-max max-w-full rounded-none py-2 px-4 lg:px-8 lg:py-4">
        <div className="flex items-center justify-between text-blue-gray-900">
          <div className="flex items-center gap-4">
            <div className="mr-4 hidden lg:block">{navList}</div>
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          </div>
          <div className="flex items-center gap-4">
            <div className="px-6" onClick={handleFeedback}>
              <PencilSquareIcon className="h-5 w-5 mt-0.5 absolute flex" />
              <Typography
                as="li"
                className="pl-6 font-normal"
              >
                <Link className="flex items-center hover:font-bold">Feedback</Link>
              </Typography>
            </div>
            {authoriseFeature && <LoginButton />}
            {authoriseFeature && <LogoutButton />}
          </div>
        </div>
        <Collapse open={openNav}>
          {navList}
        </Collapse>
      </Navbar>
      <FeedbackDialog open={openFeedback} setOpen={setOpenFeedback} />
    </>
  );
}