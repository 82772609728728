import { UserCircleIcon, ArrowPathRoundedSquareIcon } from "@heroicons/react/24/solid";
import { Link } from 'react-router-dom';
import { Typography } from "@material-tailwind/react";

import { useAuth0 } from "@auth0/auth0-react";

export const LogoutButton = () => {
    const { logout, isAuthenticated, isLoading } = useAuth0();
    
    return (
        isAuthenticated && (
            <div className="px-6" onClick={() => logout()}>
                {isLoading && <ArrowPathRoundedSquareIcon className="h-5 w-5 mt-0.5 absolute flex" />}
                {!isLoading && <UserCircleIcon className="h-5 w-5 mt-0.5 absolute flex" />}
                <Typography
                    as="li"
                    className="pl-6 font-normal"
                >
                    <Link className="flex items-center hover:font-bold">Log Out</Link>
                </Typography>
            </div>
        )
    );
}
